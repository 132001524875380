import React, { useState, useEffect } from "react";
import "./EditorAssignmentTracker.scss";
import Select from "react-select";
import PartLevelInformation from "./PartLevelInformation/PartLevelInformation";
import { customSelectStyles, customSelectTheme } from "../../utils/Components/InputComps/DropdownSelectInput/customSelectInputStyles";
import { useDispatch, useSelector } from "react-redux";
import { getEditorTrackerAssignOptions, getLevelInformation } from "../../store/features/ezEditorTracker/ezEditorTrackerSlice";

const EditorAssignmentTracker = () => {
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const dispatch = useDispatch();
  const { data: options } = useSelector((state) => state.editorTrackerData.user_tm_data.workspace_assgn_list);

  useEffect(() => {
    dispatch(getEditorTrackerAssignOptions({}));
  }, []);
  const handleWorkspaceChange = (selectedOption) => {
    setSelectedWorkspace(selectedOption);
    dispatch(getLevelInformation(selectedOption.value));
  };
  const singleValueStyles = {
    color: "var(--white)",
    backgroundColor: "var(--prime-blue)",
    borderRadius: "2px",
    padding: "0.5vh",
    width: "fit-content",
  };
  const { components } = Select;

  const BackspaceClearIndicator = (props) => {
    return components.ClearIndicator ? <components.ClearIndicator {...props} /> : null;
  };

  return (
    <div className="table_full_container_scrollable">
      <div>
        {false && (
          <div className="overlay">
            <div className="spinner"></div>
          </div>
        )}
        <div className="full_fledge_page">
          <div className="editor-tracker-container-dropdown">
            <label htmlFor="workspaceDropdown" className="workspace_assignment_id">
              Workspace Assignment ID:
            </label>
            <div className="select_input_dropdown_value">
              <Select
                id="workspaceDropdown"
                className="select-container"
                value={selectedWorkspace}
                options={options}
                isClearable
                onChange={handleWorkspaceChange}
                theme={customSelectTheme}
                placeholder="Select Workspace ID"
                styles={{
                  ...customSelectStyles,
                  control: (provided) => ({
                    ...provided,
                    width: "60vw",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    ...singleValueStyles,
                  }),
                }}
                components={{
                  BackspaceClearIndicator,
                }}
              />
            </div>
          </div>
          <PartLevelInformation assignmentId={selectedWorkspace ? selectedWorkspace.value : ""} />
        </div>
      </div>
    </div>
  );
};

export default EditorAssignmentTracker;
