import React from "react";
import { useNavigate } from "react-router-dom";
import "./CountHistory.scss";
import moment from "moment";
import ContentShortenComp from "../../../utils/functions/ContentShortenComp";
import downloadFiles from "../../../utils/functions/downloadFiles";

function Rows(props) {
  const navigate = useNavigate();

  function handleClick(e) {
    e.preventDefault();
    if (e.target.id === "file_dwnld_link") {
      downloadFiles(props.file_dwnld_link);
    } else {
      navigate(`/ez_count_info/${props.assgn_Id}`);
    }
  }

  return (
    <>
      <div className="count_row_card glass-container" id="count_row_card" onClick={handleClick}>
        <div id="date">
          <div className="table_heading mobile_view_hide_heading">Date</div>
          <div id="date" className="grid-item">
            {moment(new Date(props.date)).format("DD MMM YYYY") || "-"}
          </div>
        </div>

        <div id="file_name">
          <div className="table_heading mobile_view_hide_heading" style={{ color: "#3CC3F2", cursor: "pointer" }}>
            File Name
          </div>
          <div id="file_name" className="grid-item">
            {<ContentShortenComp content={props.file_name} max_length={30} last_chars_length={5} /> || "-"}
          </div>
        </div>
        <div id="src_lang">
          <div className="table_heading mobile_view_hide_heading">File Name</div>
          <div id="src_lang" className="grid-item">
            {(props.src_lang && props.src_lang.charAt(0).toUpperCase() + props.src_lang.slice(1)) || "-"}
          </div>
        </div>
        <div id="total_file_wc">
          <div className="table_heading mobile_view_hide_heading">Total WC</div>
          <div id="total_file_wc" className="grid-item">
            {props.total_file_wc || "-"}
          </div>
        </div>

        <div id="file_dwnld_link_container">
          <div className="table_heading mobile_view_hide_heading">File</div>
          <div id="file_dwnld_link" style={{ color: "#3CC3F2", cursor: "pointer" }} className="grid-item" onClick={handleClick}>
            Download
          </div>
        </div>
      </div>
    </>
  );
}

export default Rows;
